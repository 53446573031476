import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDatatable from "../../utils/ReactDatatable";
import { setFilter } from "../../actions/authActions";
import Toggle from "react-toggle";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import UserAddModal from "../partials/UserAddModal";
import UserUpdateModal from "../partials/UserUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { Link } from "react-router-dom";
import { Collapse } from "react-collapse";
import "react-datepicker/dist/react-datepicker.css";
import "react-toggle/style.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";
import CustomAlert from "./View/CustomAlert";
import _ from "lodash";
import usState from "../../utils/state.json";
import { availableSolutions as solutions } from "./../../utils/constants";
import Multiselect from "multiselect-react-dropdown";

const solutionsOptions = solutions.map((solution) => {
  return {
    name: solution.name,
    id: solution.subId || solution.name,
  };
});
console.log(solutionsOptions);
class Users extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "date",
        text: "Register Date",
        className: "date",
        align: "left",
        sortable: true,
      },
      {
        key: "_id",
        text: "Account Name",
        className: "name",
        align: "left",
        sortable: true,
        userDetail1: true,
      },
    ];
    if (this.props.auth.user.superadmin) {
      this.columns.push({
        key: "tabulaRx",
        text: "Solutions",
        className: "tabulaRx",
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <table class="table users">
              <tbody>
                <tr>
                  <td>
                    <div>
                      <span>TabulaRx:</span>
                      <Toggle
                        checked={record.tabulaRx}
                        icons={false}
                        onChange={() => this.toggleCheckbox("tabulaRx", record)}
                      />
                      <br />

                      <p>Exp Date: {record.expiration_tabula_format}</p>
                      <p>
                        Last Dispense:
                        {record.lastDispenseDate
                          ? moment(record.lastDispenseDate)
                              .add(5, "hours")
                              .format("L")
                          : ""}
                      </p>
                      <p>
                        Sub ID:{" "}
                        {record.subscriptionId ? record.subscriptionId : ""}
                      </p>
                      <span>Upload Disensing:</span>
                      <Toggle
                        checked={record.allow_dispensing_sheet}
                        icons={false}
                        onChange={() =>
                          this.toggleCheckbox("allow_dispensing_sheet", record)
                        }
                      />
                      <p>
                        {record.dispense_sample_file && (
                          <a
                            href={record.dispense_sample_file}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {"Download Sample"}
                          </a>
                        )}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>BonaRx:</span>
                      <Toggle
                        checked={record.bonaRx}
                        icons={false}
                        onChange={() => this.toggleCheckbox("bonaRx", record)}
                      />
                      <br />

                      <p>
                        Exp Date:{" "}
                        {record.expiration_bonarx
                          ? moment(record.expiration_bonarx)
                              .add(5, "hours")
                              .format("L")
                          : ""}
                      </p>

                      <p>
                        Sub ID:{" "}
                        {record.bonarx_subscriptionId
                          ? record.bonarx_subscriptionId
                          : ""}
                      </p>
                      <p>
                        Last Claim:
                        {record.lastDate
                          ? moment(record.lastDate).add(5, "hours").format("L")
                          : ""}
                      </p>
                      <p>RxGroup: {record.rx_group ? record.rx_group : ""}</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>RxMinder:</span>
                      <Toggle
                        checked={record.rxminder}
                        icons={false}
                        onChange={() => this.toggleCheckbox("rxminder", record)}
                      />
                      <br />
                      <br />
                      <span>Custom:</span>
                      <Toggle
                        checked={record.custom_rxminder}
                        icons={false}
                        onChange={() =>
                          this.toggleCheckbox("custom_rxminder", record)
                        }
                      />
                      <p>Exp Date: {record.expiration_rxminder_format}</p>
                      <p>Sub ID: {record.rxminder_subscriptionId}</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>XPedicare:</span>
                      <Toggle
                        checked={record.xpedicare}
                        icons={false}
                        onChange={() =>
                          this.toggleCheckbox("xpedicare", record)
                        }
                      />
                      <br />

                      <p>
                        Exp Date:{" "}
                        {record.expiration_xpedicare
                          ? moment(record.expiration_xpedicare)
                              .add(5, "hours")
                              .format("L")
                          : ""}
                      </p>

                      <p>
                        Sub ID:{" "}
                        {record.xpedicare_subscriptionId
                          ? record.xpedicare_subscriptionId
                          : ""}
                      </p>
                      <span>XPedicareRx24:</span>
                      <Toggle
                        checked={record.xpedicare24}
                        icons={false}
                        onChange={() =>
                          this.toggleCheckbox("xpedicare24", record)
                        }
                      />
                      <br />

                      <p>
                        Exp Date:{" "}
                        {record.expiration_xpedicare24
                          ? moment(record.expiration_xpedicare24)
                              .add(5, "hours")
                              .format("L")
                          : ""}
                      </p>

                      <p>
                        Sub ID:{" "}
                        {record.xpedicare24_subscriptionId
                          ? record.xpedicare24_subscriptionId
                          : ""}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>MentoRx:</span>
                      <Toggle
                        checked={record.mentorx}
                        icons={false}
                        onChange={() => this.toggleCheckbox("mentorx", record)}
                      />

                      <p>
                        Exp Date:{" "}
                        {record.expiration_mentorx
                          ? moment(record.expiration_mentorx)
                              .add(5, "hours")
                              .format("L")
                          : ""}
                      </p>
                      <p>Sub ID: {record.mentorx_subscriptionId}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span>AlereRx:</span>
                      <Toggle
                        checked={record.alererx}
                        icons={false}
                        onChange={() => this.toggleCheckbox("alererx", record)}
                      />
                      <br />

                      <p>
                        Exp Date:{" "}
                        {record.expiration_alererx
                          ? moment(record.expiration_alererx)
                              .add(5, "hours")
                              .format("L")
                          : ""}
                      </p>

                      <p>
                        Sub ID:{" "}
                        {record.alererx_subscriptionId
                          ? record.alererx_subscriptionId
                          : ""}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>DoorstepRx:</span>
                      <Toggle
                        checked={record.doorstepRx}
                        icons={false}
                        onChange={() =>
                          this.toggleCheckbox("doorstepRx", record)
                        }
                      />
                      <br />

                      <p>
                        Exp Date:{" "}
                        {record.expiration_doorstep
                          ? moment(record.expiration_doorstep)
                              .add(5, "hours")
                              .format("L")
                          : ""}
                      </p>

                      <p>
                        Sub ID:{" "}
                        {record.doorstep_subscriptionId
                          ? record.doorstep_subscriptionId
                          : ""}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>RxCreator:</span>
                      <Toggle
                        checked={record.rxcreator}
                        icons={false}
                        onChange={() =>
                          this.toggleCheckbox("rxcreator", record)
                        }
                      />

                      <p>
                        Exp Date:{" "}
                        {record.expiration_rxcreator
                          ? moment(record.expiration_rxcreator)
                              .add(5, "hours")
                              .format("L")
                          : ""}
                      </p>
                      <p>Sub ID: {record.rxcreator_subscriptionId}</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>Marketing:</span>
                      <Toggle
                        checked={record.marketing}
                        icons={false}
                        onChange={() =>
                          this.toggleCheckbox("marketing", record)
                        }
                      />

                      <p>
                        Exp Date:{" "}
                        {record.expiration_marketing
                          ? moment(record.expiration_marketing)
                              .add(5, "hours")
                              .format("L")
                          : ""}
                      </p>
                      <p>Sub ID: {record.marketing_subscriptionId}</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>Audit:</span>
                      <Toggle
                        checked={record.audit}
                        icons={false}
                        onChange={() => this.toggleCheckbox("audit", record)}
                      />

                      <p>
                        Exp Date:{" "}
                        {record.expiration_audit
                          ? moment(record.expiration_audit)
                              .add(5, "hours")
                              .format("L")
                          : ""}
                      </p>
                      <p>Sub ID: {record.audit_subscriptionId}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span>RxReach:</span>
                      <Toggle
                        checked={record.rxReach}
                        icons={false}
                        onChange={() => this.toggleCheckbox("rxReach", record)}
                      />
                      <br />

                      <p>
                        Exp Date:{" "}
                        {record.expiration_rxreach
                          ? moment(record.expiration_rxreach)
                              .add(5, "hours")
                              .format("L")
                          : ""}
                      </p>

                      <p>
                        Sub ID:{" "}
                        {record.rxreach_subscriptionId
                          ? record.rxreach_subscriptionId
                          : ""}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>CostPlus:</span>
                      <Toggle
                        checked={record.costplus}
                        icons={false}
                        onChange={() => this.toggleCheckbox("costplus", record)}
                      />

                      <p>
                        Exp Date:{" "}
                        {record.expiration_costplus
                          ? moment(record.expiration_costplus)
                              .add(5, "hours")
                              .format("L")
                          : ""}
                      </p>
                      <p>Sub ID: {record.costplus_subscriptionId}</p>
                      <p>Subdomain: {record.costplus_domain}</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>CostPlus Xtra:</span>
                      <Toggle
                        checked={record.costplus_xtra}
                        icons={false}
                        onChange={() =>
                          this.toggleCheckbox("costplus_xtra", record)
                        }
                      />

                      <p>
                        Exp Date:{" "}
                        {record.expiration_costplus_xtra
                          ? moment(record.expiration_costplus_xtra)
                              .add(5, "hours")
                              .format("L")
                          : ""}
                      </p>
                      <p>Sub ID: {record.costplus_xtra_subscriptionId}</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>Marketing+:</span>
                      <Toggle
                        checked={record.marketingp}
                        icons={false}
                        onChange={() =>
                          this.toggleCheckbox("marketingp", record)
                        }
                      />

                      <p>
                        Exp Date:{" "}
                        {record.expiration_marketingp
                          ? moment(record.expiration_marketingp)
                              .add(5, "hours")
                              .format("L")
                          : ""}
                      </p>
                      <p>Sub ID: {record.marketingp_subscriptionId}</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>RxPodcast:</span>
                      <Toggle
                        checked={record.rxPodcast}
                        icons={false}
                        onChange={() =>
                          this.toggleCheckbox("rxPodcast", record)
                        }
                      />

                      <p>
                        Exp Date:{" "}
                        {record.expiration_rxpodcast
                          ? moment(record.expiration_rxpodcast)
                              .add(5, "hours")
                              .format("L")
                          : ""}
                      </p>
                      <p>Sub ID: {record.rxpodcast_subscriptionId}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          );
        },
      });
    }

    this.config = {
      page_size: 20,
      length_menu: [20, 50, 100, 500],
      filename: "Users",
      no_data_text: "No user found!",
      button: {
        excel: false,
        print: true,
        csv: true,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: false,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      toggleNav: true,
      records: [],
      totalCount: 0,
      filterData: {
        page_number: 1,
        page_size: 20,
        sort_order: { column: "date", order: "desc" },
      },
      currentRecord: {},
      name: undefined,
      rx_group: undefined,
      first_name: undefined,
      last_name: undefined,
      website: undefined,
      phone: undefined,
      fax: undefined,
      loading: true,
      isFilterOpen: true,
      state: undefined,
      bonaRx: undefined,
      tabulaRx: undefined,
      doorstepRx: undefined,
      disable: undefined,
      multistore: undefined,
      any_subscription: undefined,
      dispense_sample_file: undefined,
      allow_dispensing_sheet: undefined,
      lastDates: [],
      lastDatesTrx: [],
      email: undefined,
      rxPodcast: undefined,
      xpedicare24: undefined,
      solutions_selected: [],
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    if (_.get(this.props, "filter.users.userPage") === true) {
      this.setState({
        ...this.props.filter.users,
      });
    } else {
      this.getData();
    }
    this.getLastDateReport();
    this.getLastDateDispense();
  }

  componentWillUnmount() {
    if (this.props.history.location.pathname.includes("user/")) {
      this.props.setFilter({ users: this.state });
    } else {
      this.props.setFilter({});
    }
  }

  getData() {
    this.setState({ loading: true });
    console.log(this.state.solutions_selected);
    let filterData = this.state.filterData;
    filterData.search = {
      ...(this.state.name !== "" && { name: this.state.name }),
      ...(this.state.email !== "" && { email: this.state.email }),
      ...(this.state.rx_group !== "" && { rx_group: this.state.rx_group }),
      ...(this.state.first_name !== "" && {
        first_name: this.state.first_name,
      }),
      ...(this.state.last_name !== "" && { last_name: this.state.last_name }),
      ...(this.state.website !== "" && { website: this.state.website }),
      ...(this.state.phone !== "" && { phone: this.state.phone }),
      ...(this.state.fax !== "" && { fax: this.state.fax }),
      ...(this.state.state !== "" && { state: this.state.state }),
      ...(this.state.any_subscription !== "" && {
        any_subscription: this.state.any_subscription,
      }),
      ...(this.state.bonaRx !== "" && { bonaRx: this.state.bonaRx }),
      ...(this.state.rxminder !== "" && { rxminder: this.state.rxminder }),
      ...(this.state.rxPodcast !== "" && { rxPodcast: this.state.rxPodcast }),
      ...(this.state.tabulaRx !== "" && { tabulaRx: this.state.tabulaRx }),
      ...(this.state.xpedicare24 !== "" && {
        xpedicare24: this.state.xpedicare24,
      }),
      ...(this.state.doorstepRx !== "" && {
        doorstepRx: this.state.doorstepRx,
      }),
      ...(this.state.disable !== "" && { disable: this.state.disable }),
      ...(this.state.multistore !== "" && {
        multistore: this.state.multistore,
      }),
      ...(this.state.subscribed !== "" && {
        subscribed: this.state.subscribed,
      }),
      ...(this.state.dispense_sample_file !== "" && {
        dispense_sample_file: this.state.dispense_sample_file,
      }),
      ...(this.state.allow_dispensing_sheet !== "" && {
        allow_dispensing_sheet: this.state.allow_dispensing_sheet,
      }),
      ...(this.state.solutions_selected.length && {
        solutions_selected: this.state.solutions_selected,
      }),
      userlist: true,
    };
    axios
      .post("/api/user-data", filterData)
      .then((res) => {
        let rxGroups = [];
        // let result = []
        // var a
        for (let i = 0; i < res.data.records.length; i++) {
          // res.data.records[i].rxPodcast =
          //   res.data.records[i].rxPodcast !== undefined
          //     ? res.data.records[i].rxPodcast
          //     : true;
          res.data.records[i].expiration_bona_format = res.data.records[i]
            .expiration_bona
            ? moment(res.data.records[i].expiration_bona)
                .add(5, "hours")
                .format("L")
            : "";
          res.data.records[i].expiration_tabula_format = res.data.records[i]
            .expiration_tabula
            ? moment(res.data.records[i].expiration_tabula)
                .add(5, "hours")
                .format("L")
            : "";
          res.data.records[i].expiration_doorstep_format = res.data.records[i]
            .expiration_doorstep
            ? moment(res.data.records[i].expiration_doorstep)
                .add(5, "hours")
                .format("L")
            : "";
          res.data.records[i].expiration_rxminder_format = res.data.records[i]
            .expiration_rxminder
            ? moment(res.data.records[i].expiration_rxminder)
                .add(5, "hours")
                .format("L")
            : "";
          res.data.records[i].date = res.data.records[i].date
            ? moment(res.data.records[i].date).add(5, "hours").format("L")
            : "";
          res.data.records[i].loginHistory = "View";
          if (res.data.records[i].rx_group)
            rxGroups = [
              ...rxGroups,
              ...res.data.records[i].rx_group.split(","),
            ];
        }

        this.setState({
          records: res.data.records,
          totalCount: res.data.count,
          loading: false,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  getLastDateReport = () => {
    axios.post("/api/lastdate-brx").then((res) => {
      this.setState({ lastDates: res.data.records });
    });
  };

  getLastDateDispense = () => {
    axios.post("/api/lastdate-trx").then((res) => {
      this.setState({ lastDatesTrx: res.data.records });
    });
  };

  editRecord(record) {
    this.setState({ currentRecord: record });
  }
  deleteRecord(record) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomAlert
            onClose={onClose}
            onChange={this.onChange}
            handleCheckPin={this.handleCheckPin}
            record={record}
          />
        );
      },
    });
  }

  handleCheckPin = (record) => {
    if (this.state.pin === "1613") {
      this.finalDelete(record);
    } else {
      alert("Pin Invalid");
    }
  };

  finalDelete = (record) => {
    axios
      .post("/api/user-delete", {
        _id: record._id,
        // delete: this.props.auth.user.user_type!=="Technician"?true:false,
        // email: this.props.auth.user.email
      })
      .then((res) => {
        if (res.status === 200) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.getData();
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  filter = (filterData) => {
    this.setState({ filterData }, () => this.getData());
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  toggleCheckbox = (type, record) => {
    const newUser = {
      id: record._id,
    };
    newUser[type] = record[type] ? false : true;
    axios
      .post("/api/user-update", newUser)
      .then((res) => {
        toast(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        this.getData();
      })
      .catch((err) => console.log(err));
  };

  onSearch = (e) => {
    e.preventDefault();
    this.getData();
  };

  resetSearch = () => {
    this.setState(
      {
        name: "",
        email: "",
        rx_group: "",
        first_name: "",
        last_name: "",
        website: "",
        phone: "",
        fax: undefined,
        state: undefined,
        bonaRx: undefined,
        tabulaRx: undefined,
        doorstepRx: undefined,
        rxPodcast: undefined,
        xpedicare24: undefined,
        disable: undefined,
        multistore: undefined,
        subscribed: undefined,
        dispense_sample_file: undefined,
        any_subscription: undefined,
        filterData: {
          page_number: 1,
          page_size: 20,
          sort_order: { column: "date", order: "desc" },
        },
      },
      () => this.getData()
    );
  };

  toggleChange = () => {
    this.setState({
      isFilterOpen: !this.state.isFilterOpen,
    });
  };

  onChangeSelect = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  navBar = () => {
    this.setState({ toggleNav: !this.state.toggleNav });
  };

  exportToCSV = (from, to) => {
    let filterData = {
      page_number: 1,
      page_size: 2000,
      sort_order: { column: "_id", order: "desc" },
      search: {
        ...(this.state.name !== "" && { name: this.state.name }),
        ...(this.state.email !== "" && { email: this.state.email }),
        ...(this.state.rx_group !== "" && { rx_group: this.state.rx_group }),
        ...(this.state.first_name !== "" && {
          first_name: this.state.first_name,
        }),
        ...(this.state.last_name !== "" && { last_name: this.state.last_name }),
        ...(this.state.website !== "" && { website: this.state.website }),
        ...(this.state.phone !== "" && { phone: this.state.phone }),
        ...(this.state.rxminder !== "" && { rxminder: this.state.rxminder }),
        ...(this.state.state !== "" && { state: this.state.state }),
        ...(this.state.bonaRx !== "" && { bonaRx: this.state.bonaRx }),
        ...(this.state.tabulaRx !== "" && { tabulaRx: this.state.tabulaRx }),
        ...(this.state.rxPodcast !== "" && { rxPodcast: this.state.rxPodcast }),
        ...(this.state.xpedicare24 !== "" && {
          xpedicare24: this.state.xpedicare24,
        }),
        ...(this.state.any_subscription !== "" && {
          any_subscription: this.state.any_subscription,
        }),
        ...(this.state.doorstepRx !== "" && {
          doorstepRx: this.state.doorstepRx,
        }),
        ...(this.state.disable !== "" && { disable: this.state.disable }),
        ...(this.state.multistore !== "" && {
          multistore: this.state.multistore,
        }),
        ...(this.state.subscribed !== "" && {
          subscribed: this.state.subscribed,
        }),
        ...(this.state.dispense_sample_file !== "" && {
          dispense_sample_file: this.state.dispense_sample_file,
        }),
        userlist: true,
      },
    };
    axios
      .post("/api/user-data", filterData)
      .then((res) => {
        toast.success("Dowloading...Please Wait!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        var headers = {
          name: "Account Name",
          email: "Email",
          rx_bin: "Rx Bin",
          rx_group: "Rx Group",
          upline: "Upline",
          assigned_date: "Assigned Date",
          first_name: "First Name",
          last_name: "Last Name",
          street: "Street",
          city: "City",
          state: "State",
          zip: "Zip",
          website: "Website",
          phone: "Phone",
          fax: "Fax",
          npi: "NPI",
          bonaRx: "BonaRx",
          tabulaRx: "TabulaRx",
          alererx: "Alererx",
          rxminder: "Rxminder",
          rxReach: "rxReach",
          comparerx: "Comparerx",
          rxcreator: "Rxcreator",
          doorstepRx: "DoorstepRx",
          rxPodcast: "Rx Podcast",
          xpedicare: "Xpedicare",
          xpedicare24: "Xpedicare24",
          expiration_bona_format: "BonaRx Expiration",
          expiration_tabula_format: "TabulaRx Expiration",
          expiration_doorstep_format: "DoorstepRx Expiration",
          expiration_rxminder_format: "Rxminder Expiration",
          expiration_alererx_format: "Alererx Expiration",
          expiration_rxReach_format: "RxReach Expiration",
          expiration_xpedicare_format: "Xpedicare Expiration",
          expiration_xpedicare24_format: "Xpedicare24 Expiration",
          date: "Register Date",
          software: "Software",
          psao: "PSAO",
          nabp: "NABP",
          allow_dispensing_sheet: "Allow Dispensing Sheet",
          dispense_sample_file: "Sample File",
          subscriptionId: "Tabula Subscription Id",
          rxminder_subscriptionId: "RxMinder Subscription Id",
          doorstep_subscriptionId: "Doorstep Subscription Id",
          xpedicare24_subscriptionId: "Xpedicare24 ID",
          xpedicare_subscriptionId: "Xpedicare ID",
          xpedicareRxcustomPage: "Xpedicare RxcustomPage",
          xpedicareRxcustom_page_name: "Xpedicare Rx Custum Page Name",
        };
        // // add columns in sheet array
        // for (let column of this.props.columns) {
        //     headers[column.key] = '"' + column.text + '"';
        // }
        var records = [];
        // add data rows in sheet array
        this.setState({ loading: false });
        for (let i in res.data.records) {
          res.data.records[i].rxPodcast =
            res.data.records[i].rxPodcast !== undefined
              ? res.data.records[i].rxPodcast
              : true;
          res.data.records[i].expiration_bona_format = res.data.records[i]
            .expiration_bona
            ? moment(res.data.records[i].expiration_bona)
                .add(5, "hours")
                .format("L")
            : "";
          res.data.records[i].expiration_tabula_format = res.data.records[i]
            .expiration_tabula
            ? moment(res.data.records[i].expiration_tabula)
                .add(5, "hours")
                .format("L")
            : "";
          res.data.records[i].expiration_doorestep_format = res.data.records[i]
            .expiration_doorestep
            ? moment(res.data.records[i].expiration_doorestep)
                .add(5, "hours")
                .format("L")
            : "";
          res.data.records[i].expiration_rxconn_format = res.data.records[i]
            .expiration_rxconn
            ? moment(res.data.records[i].expiration_rxconn)
                .add(5, "hours")
                .format("L")
            : "";
          res.data.records[i].expiration_rxminder_format = res.data.records[i]
            .expiration_rxminder_format
            ? moment(res.data.records[i].expiration_rxminder_format)
                .add(5, "hours")
                .format("L")
            : "";
          res.data.records[i].expiration_alererx_format = res.data.records[i]
            .expiration_alererx_format
            ? moment(res.data.records[i].expiration_alererx_format)
                .add(5, "hours")
                .format("L")
            : "";
          res.data.records[i].expiration_rxReach_format = res.data.records[i]
            .expiration_rxReach_format
            ? moment(res.data.records[i].expiration_rxReach_format)
                .add(5, "hours")
                .format("L")
            : "";
          res.data.records[i].expiration_xpedicare_format = res.data.records[i]
            .expiration_xpedicare_format
            ? moment(res.data.records[i].expiration_xpedicare_format)
                .add(5, "hours")
                .format("L")
            : "";
          res.data.records[i].expiration_xpedicare24_format = res.data.records[
            i
          ].expiration_xpedicare24_format
            ? moment(res.data.records[i].expiration_xpedicare24_format)
                .add(5, "hours")
                .format("L")
            : "";
          res.data.records[i].date = res.data.records[i].date
            ? moment(res.data.records[i].date).add(5, "hours").format("L")
            : "";
          let record = res.data.records[i];
          record.name = '"' + record.name + '"';
          record.street = '"' + record.street + '"';

          delete record._id;
          delete record.id;
          delete record.__v;
          records.push(record);
        }
        if (headers) {
          records.unshift(headers);
        }
        // Convert Object to JSON
        let jsonObject = JSON.stringify(records);
        let csv = this.convertToCSV(jsonObject, headers);
        let exportedFilenmae = this.config.filename + ".csv" || "export.csv";
        let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
          let link = document.createElement("a");
          if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  convertToCSV(objArray, headers) {
    const keys = Object.keys(headers);

    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in keys) {
        line +=
          array[i][keys[index]] === undefined
            ? ","
            : `${array[i][keys[index]]},`;
        line.trim(",");
      }
      str += line + "\r\n";
    }
    return str;
  }

  exportToPDF = (from, to) => {
    let filterData = {
      page_number: 1,
      page_size: 2000,
      sort_order: { column: "_id", order: "asc" },
      search: {
        ...(this.state.name !== "" && { name: this.state.name }),
        ...(this.state.email !== "" && { email: this.state.email }),
        ...(this.state.rx_group !== "" && { rx_group: this.state.rx_group }),
        ...(this.state.first_name !== "" && {
          first_name: this.state.first_name,
        }),
        ...(this.state.last_name !== "" && { last_name: this.state.last_name }),
        ...(this.state.website !== "" && { website: this.state.website }),
        ...(this.state.phone !== "" && { phone: this.state.phone }),
        ...(this.state.any_subscription !== "" && {
          any_subscription: this.state.any_subscription,
        }),
        ...(this.state.state !== "" && { state: this.state.state }),
        ...(this.state.bonaRx !== "" && { bonaRx: this.state.bonaRx }),
        ...(this.state.rxPodcast !== "" && { rxPodcast: this.state.rxPodcast }),
        ...(this.state.tabulaRx !== "" && { tabulaRx: this.state.tabulaRx }),
        ...(this.state.doorstepRx !== "" && {
          doorstepRx: this.state.doorstepRx,
        }),
        ...(this.state.disable !== "" && { disable: this.state.disable }),
        ...(this.state.multistore !== "" && {
          multistore: this.state.multistore,
        }),
        ...(this.state.subscribed !== "" && {
          subscribed: this.state.subscribed,
        }),
        ...(this.state.dispense_sample_file !== "" && {
          dispense_sample_file: this.state.dispense_sample_file,
        }),
        userlist: true,
      },
    };
    axios.post("/api/user-data", filterData).then((res) => {
      this.setState({ loading: false });
      let sTable = "";
      sTable += "<table>";
      sTable += "<thead>";
      sTable += "<tr>";
      for (let column of this.columns) {
        sTable += "<th>" + column.text + "</th>";
      }
      sTable += "</tr>";
      sTable += "</thead>";
      sTable += "<tbody>";
      for (let i in res.data.records) {
        let record = res.data.records[i];
        sTable += "<tr>";
        for (let column of this.columns) {
          if (column.cell && typeof column.cell === "function") {
            sTable += "<td></td>";
          } else if (record[column.key]) {
            sTable += "<td>" + record[column.key] + "</td>";
          } else {
            sTable += "<td></td>";
          }
        }
        sTable += "</tr>";
      }
      sTable += "</tbody>";
      sTable += "</table>";

      var style = "<style>";
      style = style + "table {width: 100%;font: 17px Calibri;}";
      style =
        style +
        "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
      style = style + "padding: 2px 3px;textAlign:left;}";
      style = style + "</style>";

      var win = window.open("", "_blank");
      win.document.write("<html><head>");
      win.document.write("<title>" + this.config.filename + "</title>");
      win.document.write(style);
      win.document.write("</head>");
      win.document.write("<body>");
      win.document.write("<h1>" + this.config.filename + "</h1>");
      win.document.write(sTable);
      win.document.write("</body></html>");
      win.print();
      win.close();
    });
  };

  getuserDetail1 = (record) => {
    return (
      <>
        <Link to={`user/${record.id}`}>{record.name}</Link>
        <div>{record.state + " " + record.zip}</div>
        <div>{record.first_name + " " + record.last_name}</div>
        <div>{"OTP Phone: " + record.phone}</div>
        <div>{"Pharmacy Phone: " + record.phone1}</div>
        <div>{"Fax: " + record.fax}</div>
        <div>{"Email: " + record.email}</div>
        <div>{"Register Date: " + record.date}</div>
        {record.software && <div>{"PMS: " + record.software}</div>}
        <Fragment>
          <button
            data-toggle="modal"
            data-target="#update-user-modal"
            className="btn btn-primary btn-sm"
            onClick={() => this.editRecord(record)}
            style={{ marginRight: "5px" }}
          >
            <i className="fa fa-edit"></i>
          </button>
          <button
            className="btn btn-danger btn-sm"
            onClick={() => this.deleteRecord(record)}
          >
            <i className="fa fa-trash"></i>
          </button>
        </Fragment>
        &nbsp;&nbsp;
        {<Link to={`loginHistory/${record._id}`}>Login History</Link>}
        <hr></hr>
        <div>Disable:</div>
        <Toggle
          checked={record.disable}
          icons={false}
          onChange={() => this.toggleCheckbox("disable", record)}
        />
        <div>Mark As child:</div>
        <Toggle
          checked={record.child}
          icons={false}
          onChange={() => this.toggleCheckbox("child", record)}
        />
        <div>Mark As MultiStore Owner:</div>
        <Toggle
          checked={record.multistore}
          icons={false}
          onChange={() => this.toggleCheckbox("multistore", record)}
        />
        <div>Demo Account:</div>
        <Toggle
          checked={record.dummy}
          icons={false}
          onChange={() => this.toggleCheckbox("dummy", record)}
        />
      </>
    );
  };

  render() {
    let records = this.state.records;
    records.map((each) => {
      let matchBrx = this.state.lastDates.find(
        (eachReord) =>
          each.rx_group && each.rx_group.split(",").includes(eachReord._id)
      );
      if (matchBrx) each.lastDate = matchBrx.date_submitted;

      let matchTrx = this.state.lastDatesTrx.find(
        (eachReord) => each.email && each.email === eachReord._id
      );
      if (matchTrx) each.lastDispenseDate = matchTrx.dispense_date;
      return each;
    });
    return (
      <div className="d-flex group-list" id="wrapper">
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}

        {this.props.auth.user.superadmin && (
          <UserAddModal getData={this.getData} />
        )}
        <UserUpdateModal
          record={this.state.currentRecord}
          getData={this.getData}
        />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            {this.props.auth.user.superadmin && (
              <button
                className="btn btn-primary float-right mt-3 mr-2 a"
                data-toggle="modal"
                data-target="#add-user-modal"
              >
                <FontAwesomeIcon icon={faPlus} /> Add User
              </button>
            )}
            <h1 className="mt-2 text-primary">Group List</h1>
            <div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={this.state.isFilterOpen}
                  onClick={this.toggleChange}
                  id="isFilterOpen"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Show Filter
                </label>
              </div>
              <Collapse isOpened={this.state.isFilterOpen}>
                <form noValidate onSubmit={this.onSearch}>
                  <div className="form-row" style={{ width: "1000px" }}>
                    <div className="form-group col-md-3">
                      <label htmlFor="state">Account Name</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.name}
                        id="name"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="email">Email</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.email}
                        id="email"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="first_name">First Name</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.first_name}
                        id="first_name"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="last_name">Last Name</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.last_name}
                        id="last_name"
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-row" style={{ width: "1000px" }}>
                    <div className="form-group col-md-3">
                      <label htmlFor="phone">Phone</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.phone}
                        id="phone"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="state">State</label>
                      <select
                        name="state"
                        onClick={this.onChangeSelect}
                        className="form-control"
                        id="state"
                      >
                        <option value="" selected="">
                          Any
                        </option>
                        {Object.keys(usState).map((each, i) => (
                          <option
                            key={i}
                            value={each}
                            selected={this.state.state === each}
                          >
                            {usState[each]}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="dispense_sample_file">
                        Dispense Sample File
                      </label>
                      <select
                        className="form-control"
                        onChange={this.onChangeSelect}
                        id="dispense_sample_file"
                      >
                        <option value="">Any</option>
                        <option value="1">Yes</option>
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="allow_dispensing_sheet">
                        Allow Dispensing Upload
                      </label>
                      <select
                        className="form-control"
                        onChange={this.onChangeSelect}
                        id="allow_dispensing_sheet"
                      >
                        <option value="">Any</option>
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="disable">Disable</label>
                      <select
                        className="form-control"
                        onChange={this.onChangeSelect}
                        id="disable"
                      >
                        <option value="">Any</option>
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="tabulaRx">MustiStore Owner</label>
                      <select
                        className="form-control"
                        onChange={this.onChangeSelect}
                        id="multistore"
                      >
                        <option value="">Any</option>
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="bonaRx">Solutions</label>
                      <Multiselect
                        options={solutionsOptions}
                        onSelect={(e) => {
                          this.setState({ solutions_selected: e });
                        }}
                        selectedValue={this.state.solutions_selected}
                        displayValue="name"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="bonaRx">BonaRx</label>
                      <select
                        className="form-control"
                        onChange={this.onChangeSelect}
                        id="bonaRx"
                      >
                        <option value="">Any</option>
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="rxPodcast">RxPodcast</label>
                      <select
                        className="form-control"
                        onChange={this.onChangeSelect}
                        id="rxPodcast"
                      >
                        <option value="">Any</option>
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="tabulaRx">TabulaRx</label>
                      <select
                        className="form-control"
                        onChange={this.onChangeSelect}
                        id="tabulaRx"
                      >
                        <option value="">Any</option>
                        <option
                          value="1"
                          selected={
                            this.state.tabulaRx === "1" ? "selected" : ""
                          }
                        >
                          Yes
                        </option>
                        <option
                          value="2"
                          selected={
                            this.state.tabulaRx === "2" ? "selected" : ""
                          }
                        >
                          No
                        </option>
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="doorstepRx">DoorstepRx</label>
                      <select
                        className="form-control"
                        onChange={this.onChangeSelect}
                        id="doorstepRx"
                      >
                        <option value="">Any</option>
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="rxminder">RxMinder</label>
                      <select
                        className="form-control"
                        onChange={this.onChangeSelect}
                        id="rxminder"
                      >
                        <option value="">Any</option>
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="xpedicare24">XPedicare24</label>
                      <select
                        className="form-control"
                        onChange={this.onChangeSelect}
                        id="xpedicare24"
                      >
                        <option value="">Any</option>
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                      </select>
                    </div>
                    {/* <div className="form-group col-md-3">
                      <label htmlFor="subscribed">Subscribed to Any Solutioun</label>
                      <select
                        className="form-control"
                        onChange={this.onChangeSelect}
                        id="any_subscription"
                      >
                        <option value="">Any</option>
                        <option value="1" selected={
                            this.state.any_subscription === "1" ? "selected" : ""
                          }>Yes</option>
                        
                      </select>
                    </div> */}
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Filter
                  </button>
                  <button
                    type="reset"
                    onClick={this.resetSearch}
                    className="btn btn-secordry"
                  >
                    Reset
                  </button>
                </form>
              </Collapse>
            </div>
            <br></br>

            <ReactDatatable
              config={this.config}
              records={records}
              columns={this.columns}
              onChange={this.filter}
              dynamic={true}
              total_record={this.state.totalCount}
              loading={true}
              exportToCSV={this.exportToCSV}
              exportToPDF={this.exportToPDF}
              getuserDetail1={this.getuserDetail1}
            />
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  records: state.records,
  filter: state.filterData,
});

export default connect(mapStateToProps, { setFilter })(Users);
