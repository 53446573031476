import React from "react";
import DatePicker from "react-datepicker";
import classnames from "classnames";

const BonaRx = (props) => {
  const { errors, expiration_bonarx, rx_group } = props;
  return (
    <>
      <div className="card">
        <div className="card-header" id="BonaRx">
          <h5 className="mb-0">
            <button
              type="button"
              className="btn btn-link"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              BonaRx (Upline - {props.upline})
            </button>
          </h5>
        </div>
        <div
          id="collapseOne"
          className="collapse show"
          aria-labelledby="BonaRx"
          data-parent="#accordion"
        >
          <div className="card-body">
            <div className="row mt-2">
              <div className="col-md-3">
                <label htmlFor="name">RX Group</label>
              </div>
              <div className="col-md-9">
                <input
                  onChange={props.onChange}
                  value={rx_group}
                  id="rx_group"
                  type="text"
                  error={errors.rx_group}
                  className={"form-control"}
                />
                <span className="text-danger">{errors.rx_group}</span>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-3">
                <label htmlFor="bonarx">Exp Date</label>
              </div>
              <div className="col-md-9">
                <DatePicker
                  selected={expiration_bonarx}
                  id="expiration_bonarx"
                  onChange={(date) => props.setDate(date, "expiration_bonarx")}
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-3">
                <label htmlFor="bonarx_subscriptionId">Subscription Id</label>
              </div>
              <div className="col-md-9">
                <input
                  onChange={props.onChange}
                  TabulaRx
                  value={props.bonarx_subscriptionId}
                  id="bonarx_subscriptionId"
                  type="text"
                  error={errors.bonarx_subscriptionId}
                  className={classnames("form-control", {
                    invalid: errors.bonarx_subscriptionId,
                  })}
                />
                <span className="text-danger">
                  {errors.bonarx_subscriptionId}
                </span>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-3">
                <label htmlFor="fax">Rx Group Associated</label>
              </div>
              <div className="col-md-9">
                <input
                  onChange={props.onChange}
                  value={props.rx_group_associated}
                  id="rx_group_associated"
                  type="text"
                  error={errors.rx_group_associated}
                  className={"form-control"}
                />
                <span className="text-danger">
                  {errors.rx_group_associated}
                </span>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-3">
                <label htmlFor="bonarx">Child</label>
              </div>
              <div className="col-md-9">
                <input
                  type="checkbox"
                  class="form-check-input"
                  checked={props.child}
                  onClick={props.toggleChange}
                  id="child"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BonaRx;
