import React from "react";

class XpedicareVideo extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  render() {
    return (
      <div>
        <div className="modal fade" id="how-it-works" data-reset="true">
          <div className="modal-dialog modal-xl" style={{ maxWidth: "1350px" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  Step-by-step guide to getting started:
                </h4>
                <img
                  src={
                    "https://rxreachanalytis.s3.amazonaws.com/logo/XPedicareRx-24.png"
                  }
                  height={50}
                  style={{
                    marginLeft: "20px",
                    position: "absolute",
                    right: "40px",
                  }}
                />
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body center">
                <div className="row mt-2">
                  <div className="col-md-6">
                    <div class="card">
                      <video controls>
                        <source
                          src="https://d3l9g5ay50k79j.cloudfront.net/xpedicare-video/1.+Xpedicare+Telemedicine+Onboarding+Guide+_Verification+Process.mp4"
                          type="video/mp4"
                        />
                      </video>
                      <div class="card-body">
                        <h5 class="card-title">1. Verification Process</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="card">
                      <video controls>
                        <source
                          src="https://d3l9g5ay50k79j.cloudfront.net/xpedicare-video/2.+Managing+Links+in+Xpedicare+_Create+and+Manage+Links.mp4"
                          type="video/mp4"
                        />
                      </video>
                      <div class="card-body">
                        <h5 class="card-title">
                          2. How to Create and Manage Links
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-6">
                    <div class="card">
                      <video controls>
                        <source
                          src="https://d3l9g5ay50k79j.cloudfront.net/xpedicare-video/3.+XPedicareRx_+How+to+Claim+Prescriptions+and+Customize+Your+Landing+Page.mp4"
                          type="video/mp4"
                        />
                      </video>
                      <div class="card-body">
                        <h5 class="card-title">
                          3. How to Claim Prescriptions and Customize Your
                          Landing Page
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="card">
                      <video controls>
                        <source
                          src="https://d3l9g5ay50k79j.cloudfront.net/xpedicare-video/4.+XPedicareRx+Tools+and+Resources+%F0%9F%9B%A0%EF%B8%8F.mp4"
                          type="video/mp4"
                        />
                      </video>
                      <div class="card-body">
                        <h5 class="card-title">4. Tools and Resources 🛠️</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-3"></div>
                  <div className="col-md-6">
                    <div class="card">
                      <video controls>
                        <source
                          src="https://d3l9g5ay50k79j.cloudfront.net/xpedicare-video/instore.mp4"
                          type="video/mp4"
                        />
                      </video>
                      <div class="card-body">
                        <h5 class="card-title">5. In-Store Assessment</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3"></div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-success btn-lg ">
                  <a
                    href="https://app.xpedicare.com/#/?company=psgarx"
                    target="_blank"
                    style={{ color: "#fff", textDecoration: "none" }}
                  >
                    Click to Continue
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default XpedicareVideo;
