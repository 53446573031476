import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../utils/XPedicareRx.png";
import xpx from "../../utils/xpx.jpg";
import XpedicareVideo from "../partials/XpedicareVideo";

class Rxreach extends React.Component {
  render() {
    return (
      <div className="section">
        <div className="container">
          <XpedicareVideo />
          <div className="row">
            <div className="col-md-12">
              <div className="section-title m-3">
                <div className="row">
                  <div className="col-md-8">
                    {" "}
                    <img
                      src={
                        "https://rxreachanalytis.s3.amazonaws.com/logo/XPedicareRx-24.png"
                      }
                      height={60}
                      style={{ marginLeft: "20px" }}
                    />
                  </div>
                  <div className="col-md-4" style={{ textAlign: "right" }}>
                    <button
                      type="button"
                      style={{
                        marginBottom: "10px",
                        width: "205px",
                      }}
                      className="btn btn-success btn-lg "
                    >
                      <a
                        data-target="#how-it-works"
                        data-toggle="modal"
                        // href="https://app.xs
                        style={{ color: "#fff", textDecoration: "none" }}
                      >
                        LOGIN/REGISTER
                      </a>
                    </button>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h1
                      className="card-title text-primary"
                      style={{ fontWeight: "bold", fontSize: "47px" }}
                    >
                      Virtual Care on Demand at Your Pharmacy
                    </h1>
                    <div className="row">
                      <div className="col-md-8">
                        <p className="card-text" style={{ fontSize: "20px" }}>
                          <b>XPedicareRx-24</b> is a powerful telemedicine platform that enables pharmacies to seamlessly integrate a fully licensed online clinic into their website through a custom link. Licensed in <b>all 50 states</b>, this innovative solution allows pharmacies to extend their clinical services beyond brick-and-mortar operations, giving patients convenient access to licensed healthcare providers from anywhere.
                          <br />
                          <br />
                          The program supports a wide range of services, including <b>Acute Care, Chronic Care Management (CCM)</b>, and access to <b>over 115 compounded medications</b>—all delivered through a secure, easy-to-use virtual experience.

                          <br />
                          <br />
                          Pharmacies can offer consultations for conditions such as:
                          <br />

                          <table class="table table-bordered">

                            <tbody>
                              <tr>
                                <td>Erectile Dysfunction (ED)</td>
                                <td>COVID-19 Consults</td>
                              </tr>
                              <tr>
                                <td>Low Dose Naltrexone (LDN)</td>
                                <td>Asthma</td>
                              </tr>
                              <tr>
                                <td>Continuous Glucose Monitoring (CGM)</td>
                                <td>Smoking Cessation</td>
                              </tr>
                              <tr>
                                <td>Oral Contraceptives</td>
                                <td>PrEP (HIV Prevention)</td>
                              </tr>
                              <tr>
                                <td>Alopecia</td>
                                <td>EpiPen Prescriptions</td>
                              </tr>
                              <tr>
                                <td>Aging & Hormone Support</td>
                                <td>Cold Sores</td>
                              </tr>
                              <tr>
                                <td>Urinary Tract Infections (UTIs)</td>
                                <td>Acne</td>
                              </tr>
                              <tr>
                                <td>Diabetes Care</td>
                                <td>Allergies</td>
                              </tr>
                              <tr>
                                <td>Travel Medications</td>
                                <td>Custom Compounded Medications and more.</td>
                              </tr>
                            </tbody>
                          </table>

                        </p>

                      </div>

                      <div className="col-md-4" style={{ textAlign: "center" }}>
                        {/* <button
                          type="button"
                          style={{ marginTop: "10px", width: "205px" }}
                          data-target="#how-it-works"
                          data-toggle="modal"
                          className="btn btn-success btn-lg"
                        >
                          <a style={{ color: "#fff", textDecoration: "none" }}>
                            How-to videos
                          </a>
                        </button> */}
                        <br></br>
                        <img
                          style={{ width: "70%", marginTop: "12px" }}
                          src={xpx}
                        />
                      </div>
                      <div className="col-md-12"><p className="card-text" style={{ fontSize: "20px" }}>
                        By embedding the online clinic into their website, pharmacies can significantly enhance patient engagement, expand access to care, and open new streams of revenue—all while supporting better health outcomes in their communities.
                      </p></div>
                      <hr />
                      <div className="col-md-11">
                        <p className="card-text" style={{ fontSize: "14px" }}>
                          <span style={{ fontSize: "14px" }}>
                            <br></br>
                            <b>First time user? You must register</b>
                          </span>
                        </p>
                      </div>
                      <br></br>
                      <br></br>
                      <div className="col-md-11">
                        <br></br>

                        <img
                          src="https://rxreachanalytis.s3.us-east-1.amazonaws.com/xpedicare-video/allStates.png"
                          width={"100%"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Rxreach;
