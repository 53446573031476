import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDatatable from "../../utils/ReactDatatable";
import { logoutUser, setFilter } from "../../actions/authActions";
import { connect } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import DrugsAddModal from "../partials/DrugsAddModal";
import Drugcon from "../partials/Drugcon";
import { faPlus, faBackward } from "@fortawesome/free-solid-svg-icons";
import DrugsUpdateModal from "../partials/DrugsUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { Collapse } from "react-collapse";
import moment from "moment";
import _ from "lodash";
import AddNDC from "../partials/AddNDC";

class Drugs extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "ndc",
        text: "NDC",
        className: "ndc",
        align: "left",
        sortable: true,
        link: "drug",
      },
      {
        key: "name",
        text: "Product Description",
        className: "name",
        align: "left",
        sortable: true,
      },
      {
        key: "website",
        text: "Coupon",
        className: "website",
        align: "left",
        sortable: true,
        coupon: true,
      },
      {
        key: "formatawp",
        text: "AWP",
        className: "formatawp",
        align: "left",
        sortable: true,
        // currency: true,
      },
      {
        key: "form_code",
        text: "Form Code",
        className: "form_code",
        align: "left",
        sortable: true,
      },
      {
        key: "gcn",
        text: "GCN",
        className: "gcn",
        align: "left",
        sortable: true,
      },
      {
        key: "gcn_seq",
        text: "GCN Seq",
        className: "gcn_seq",
        align: "left",
        sortable: true,
      },
      {
        key: "ahfs_desc",
        text: "Therapeutic Class",
        className: "ahfs_desc",
        align: "left",
        sortable: true,
      },
      {
        key: "group",
        text: "Group",
        className: "group",
        align: "left",
        sortable: true,
      },
      {
        key: "dea",
        text: "DEA Class",
        className: "dea",
        align: "left",
        sortable: true,
      },
      {
        key: "route_desc",
        text: "Route Desc",
        className: "route_desc",
        align: "left",
        sortable: true,
      },
    ];

    if (this.props.auth.user.superadmin) {
      this.columns.push({
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                data-toggle="modal"
                data-target="#update-drug-modal"
                className="btn btn-primary btn-sm"
                onClick={() => this.editRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => this.deleteRecord(record)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Fragment>
          );
        },
      });
    }
    if (this.props.auth.user.superadmin) {
      this.config = {
        page_size: 20,
        length_menu: [20, 50, 100],
        filename: "Drugs",
        no_data_text: "No drug found!",
        button: {
          excel: false,
          print: false,
          csv: true,
          range: false,
          advanceSearch: "Search NDC or Name",
        },
        language: {
          length_menu: "Show _MENU_ result per page",
          filter: "Filter in records...",
          info: "Showing _START_ to _END_ of _TOTAL_ records",
          pagination: {
            first: "First",
            previous: "Previous",
            next: "Next",
            last: "Last",
          },
        },
        show_length_menu: true,
        show_filter: false,
        show_pagination: true,
        show_info: true,
      };
    } else {
      this.config = {
        page_size: 20,
        length_menu: [20, 50, 100],
        filename: "Drugs",
        no_data_text: "No drug found!",
        button: {
          excel: false,
          print: false,
          csv: false,
          range: false,
          advanceSearch: "Search NDC or Name",
        },
        language: {
          length_menu: "Show _MENU_ result per page",
          filter: "Filter in records...",
          info: "Showing _START_ to _END_ of _TOTAL_ records",
          pagination: {
            first: "First",
            previous: "Previous",
            next: "Next",
            last: "Last",
          },
        },
        show_length_menu: true,
        show_filter: false,
        show_pagination: true,
        show_info: true,
      };
    }

    this.state = {
      records: [],
      totalCount: 0,
      filterData: {
        page_number: 1,
        page_size: 20,
        sort_order: { column: "ndc", order: "desc" },
      },
      currentRecord: {},
      name: undefined,
      ndc: undefined,
      dea: undefined,
      ahfs_desc: undefined,
      route_desc: undefined,
      website: undefined,
      group: undefined,
      gcn: undefined,
      gcn_seq: undefined,
      loading: false,
      isFilterOpen: true,
      advanceSearch: undefined,
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    if (_.get(this.props, "filter.drugs.drugPage") === true) {
      this.setState({
        ...this.props.filter.drugs,
      });
    } else {
      this.getData();
    }
    // if (window.location.search.split('add=')) {
    //     $("button.btn.btn-primary.float-right.mt-3.mr-2").trigger("click")
    // }
  }

  getData = () => {
    this.setState({ loading: true });
    let filterData = this.state.filterData;
    filterData.search = {
      ...(this.state.name !== "" && { name: this.state.name }),
      ...(this.state.ndc !== "" && { ndc: this.state.ndc }),
      ...(this.state.route_desc !== "" && {
        route_desc: this.state.route_desc,
      }),
      ...(this.state.dea !== "" && { dea: this.state.dea }),
      ...(this.state.ahfs_desc !== "" && { ahfs_desc: this.state.ahfs_desc }),
      ...(this.state.website !== "" && { website: this.state.website }),
      ...(this.state.group !== "" && { group: this.state.group }),
      ...(this.state.gcn !== "" && { gcn: this.state.gcn }),
      ...(this.state.gcn_seq !== "" && { gcn_seq: this.state.gcn_seq }),
      ...(this.state.advanceSearch !== "" && {
        advanceSearch: this.state.advanceSearch,
      }),
    };
    axios
      .post("/api/drugs", filterData)
      .then((res) => {
        let result = [];
        var formatawp;
        for (let i = 0; i < res.data.records.length; i++) {
          let each = res.data.records[i];
          if (each.name != "") {
            if (each.awp > 0) {
              each.awp = each.awp.replace("$", "");
              each.formatawp = each.awp
                ? Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(each.awp)
                : Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(0);
            } else {
              formatawp = "$0";
            }
            result.push(each);
          }
        }
        this.setState({
          records: result,
          totalCount: res.data.count,
          loading: false,
          filterData: { ...this.state.filterData, pagination: 1 },
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  editRecord(record) {
    this.setState({ currentRecord: record });
  }

  deleteRecord(record) {
    axios
      .post("/api/drug-delete", { _id: record._id })
      .then((res) => {
        if (res.status === 200) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
    this.getData();
  }

  filter = (filterData) => {
    this.setState({ filterData }, () => this.getData());
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSearch = (e) => {
    e.preventDefault();
    this.getData();
  };

  advanceSearch = (advanceSearch) => {
    this.setState(
      {
        name: undefined,
        ndc: undefined,
        dea: undefined,
        ahfs_desc: undefined,
        website: undefined,
        route_desc: undefined,
        group: undefined,
        gcn: undefined,
        gcn_seq: undefined,
        advanceSearch: advanceSearch,
        filterData: {
          page_number: 1,
          page_size: 20,
          sort_order: { column: "_id", order: "asc" },
        },
      },
      () => this.getData()
    );
  };

  resetSearch = () => {
    this.setState(
      {
        name: undefined,
        ndc: undefined,
        dea: undefined,
        ahfs_desc: undefined,
        route_desc: undefined,
        website: undefined,
        group: undefined,
        gcn: undefined,
        gcn_seq: undefined,
        advanceSearch: undefined,
        filterData: {
          page_number: 1,
          page_size: 20,
          sort_order: { column: "_id", order: "asc" },
        },
      },
      () => this.getData()
    );
  };

  toggleChange = () => {
    this.setState({
      isFilterOpen: !this.state.isFilterOpen,
    });
  };

  onChangeSelect = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  setAlphabetClick = (e) => {
    this.setState({ name: e.target.id }, () => this.getData());
  };

  exportToCSV = (from, to) => {
    this.setState({ loading: true });
    let filterData = {
      page_number: 1,
      page_size: 20000,
      sort_order: { column: "_id", order: "asc" },
    };
    filterData.search = {
      ...(this.state.name !== "" && { name: this.state.name }),
      ...(this.state.ndc !== "" && { ndc: this.state.ndc }),
      ...(this.state.route_desc !== "" && {
        route_desc: this.state.route_desc,
      }),
      ...(this.state.dea !== "" && { dea: this.state.dea }),
      ...(this.state.ahfs_desc !== "" && { ahfs_desc: this.state.ahfs_desc }),
      ...(this.state.website !== "" && { website: this.state.website }),
      ...(this.state.group !== "" && { group: this.state.group }),
      ...(this.state.gcn !== "" && { gcn: this.state.gcn }),
      ...(this.state.gcn_seq !== "" && { gcn_seq: this.state.gcn_seq }),
      ...(this.state.advanceSearch !== "" && {
        advanceSearch: this.state.advanceSearch,
      }),
    };
    axios
      .post("/api/drugs", filterData)
      .then((res) => {
        toast.success("Dowloading...Please Wait!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        var headers = {
          name: "Name",
          ndc: "NDC",
          supplier_name: "Supplier Name",
          // "website": "Coupans",
          hic_code: "HIC Code",
          hic_desc: "HIC Desc",
          uom: "UOM",
          route_desc: "Route Desc",
          ahfs_code: "AHFS Code",
          ahfs_desc: "AHFS Desc",
          formatawp: "AWP",
          package_awp: "Package AWP",
          price_per_dose: "Price Per Dose",
          acq_cost: "ACQ Cost",
          wholesale_cost: "Wholesale Cost",
          contract_cost: "Contract Cost",
          retail_price: "Retail Price",
          bu_per_package: "Bu Per Package",
          dea: "DEA",
          dea_desc: "DEA Desc",
          fdb_package_size: "FDB Package Size",
          fine_line: "Fine Line",
          fine_line_desc: "Fine Line Desc",
          form_code: "Form Code",
          gcn: "GCN",
          gcn_seq: "GCN Seq",
          generic_hicl: "Generic Hicl",
          generic_name: "Generic Name",
          gtin: "GTIN",
          hazard: "Hazard",
          hazard_desc: "Hazard Desc",
          hcpcs: "HCPCS",
          hri: "HRI",
          niosh_code: "Niosh Code",
          niosh_desc: "Niosh Desc",
          omp: "OMP",
          omp_desc: "OMP Desc",
          omp_indicator: "OMP Indicator",
          orange_code: "Orange Code",
          category: "Category",
          category_desc: "Category Desc",
          group: "Group",
          group_desc: "Group Desc",
          route_code: "Route Code",
          source: "Source",
          unit_dose_code: "Unit Dose Code",
          unit_dose_product: "Unit Dose Product",
          unit_size_code: "Unit Size Code",
          unit_size_qty: "Unit Size Qty",
          unit_strength_code: "Unit Strength Code",
          unit_strength_qty: "Unit Strength Qty",
          upc_barcode: "UPC Barcode",
          website: "Website",
          mme: "MME",
        };
        // // add columns in sheet array
        // for (let column of this.props.columns) {
        //     headers[column.key] = '"' + column.text + '"';
        // }
        var records = [];
        // add data rows in sheet array
        this.setState({ loading: false });
        for (let i in res.data.records) {
          let record = res.data.records[i];
          records.push(record);
        }
        if (headers) {
          records.unshift(headers);
        }
        // Convert Object to JSON
        let jsonObject = JSON.stringify(records);
        let csv = this.convertToCSV(jsonObject, headers);
        let exportedFilenmae = this.config.filename + ".csv" || "export.csv";
        let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
          let link = document.createElement("a");
          if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  convertToCSV(objArray, headers) {
    const keys = Object.keys(headers);
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in keys) {
        line +=
          array[i][keys[index]] === undefined
            ? ","
            : array[i][keys[index]] + ",";
        line.trim(",");
      }
      str += line + "\r\n";
    }
    return str;
  }

  exportToPDF = (from, to) => {
    let filterData = {
      page_number: 1,
      page_size: 20000,
      sort_order: { column: "_id", order: "asc" },
    };
    filterData.search = {
      ...(this.state.name !== "" && { name: this.state.name }),
      ...(this.state.ndc !== "" && { ndc: this.state.ndc }),
      ...(this.state.route_desc !== "" && {
        route_desc: this.state.route_desc,
      }),
      ...(this.state.dea !== "" && { dea: this.state.dea }),
      ...(this.state.ahfs_desc !== "" && { ahfs_desc: this.state.ahfs_desc }),
      ...(this.state.website !== "" && { website: this.state.website }),
      ...(this.state.group !== "" && { group: this.state.group }),
      ...(this.state.gcn !== "" && { gcn: this.state.gcn }),
      ...(this.state.gcn_seq !== "" && { gcn_seq: this.state.gcn_seq }),
      ...(this.state.advanceSearch !== "" && {
        advanceSearch: this.state.advanceSearch,
      }),
    };
    axios.post("/api/drugs", filterData).then((res) => {
      this.setState({ loading: false });
      let sTable = "";
      sTable += "<table>";
      sTable += "<thead>";
      sTable += "<tr>";
      for (let column of this.columns) {
        sTable += "<th>" + column.text + "</th>";
      }
      sTable += "</tr>";
      sTable += "</thead>";
      sTable += "<tbody>";
      for (let i in res.data.records) {
        let record = res.data.records[i];
        sTable += "<tr>";
        for (let column of this.columns) {
          if (column.cell && typeof column.cell === "function") {
            sTable += "<td></td>";
          } else if (record[column.key]) {
            sTable += "<td>" + record[column.key] + "</td>";
          } else {
            sTable += "<td></td>";
          }
        }
        sTable += "</tr>";
      }
      sTable += "</tbody>";
      sTable += "</table>";

      var style = "<style>";
      style = style + "table {width: 100%;font: 17px Calibri;}";
      style =
        style +
        "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
      style = style + "padding: 2px 3px;textAlign:left;}";
      style = style + "</style>";

      var win = window.open("", "_blank");
      win.document.write("<html><head>");
      win.document.write("<title>" + this.config.filename + "</title>");
      win.document.write(style);
      win.document.write("</head>");
      win.document.write("<body>");
      win.document.write("<h1>" + this.config.filename + "</h1>");
      win.document.write(sTable);
      win.document.write("</body></html>");
      win.print();
      win.close();
    });
  };

  componentWillUnmount() {
    if (this.props.history.location.pathname.includes("drug/")) {
      this.state.drugPage = true;
      this.props.setFilter({ drugs: this.state });
    } else {
      this.props.setFilter({});
    }
  }

  render() {
    console.log(this.state.loading);
    if (
      (this.props.auth.user.expiration_tabula === "" ||
        this.props.auth.user.expiration_tabula === undefined ||
        this.props.auth.user.expiration_tabula < moment().format()) &&
      this.props.auth.user.superadmin !== true
    ) {
      window.location.href = "/choose-plan?solution=TabulaRx";
    }
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    return (
      <div className="d-flex" id="wrapper">
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}
        <AddNDC />
        <DrugsAddModal getData={this.getData} />
        <Drugcon getData={this.getData} />
        <DrugsUpdateModal
          record={this.state.currentRecord}
          getData={this.getData}
        />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            {this.props.auth.user.superadmin && (
              <button
                className="btn btn-primary float-right mt-3 mr-2"
                data-toggle="modal"
                data-target="#add-drug-modal"
              >
                <FontAwesomeIcon icon={faPlus} /> Add drug
              </button>
            )}
            <button
              className="btn btn-primary float-right mt-3 mr-2"
              data-toggle="modal"
              data-target="#add-drug-modal"
            >
              Don’t see a drug – Let us know
            </button>

            <h1 className="mt-2 text-primary">Drug List</h1>
            <button className="btn btn-warning">
              <FontAwesomeIcon icon={faBackward} />
              <Link to="/dashboard"> Go to Dashboard</Link>
            </button>
            <br></br>
            <br></br>
            <div className="form-group col-md-12 alphabet">
              {alphabet.map((each, index) => {
                return (
                  <>
                    <span
                      style={{ cursor: "pointer" }}
                      id={each}
                      onClick={this.setAlphabetClick}
                    >
                      {each}
                      {"  "}
                    </span>
                  </>
                );
              })}
            </div>
            <div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={this.state.isFilterOpen}
                  onClick={this.toggleChange}
                  id="isFilterOpen"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Show Filter
                </label>
              </div>
              <Collapse isOpened={this.state.isFilterOpen}>
                <form noValidate onSubmit={this.onSearch}>
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <label htmlFor="state">Product Description</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.name}
                        id="name"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="ndc">NDC</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.ndc}
                        id="ndc"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="ahfs_desc">Group</label>
                      <select
                        className="form-control"
                        onChange={this.onChangeSelect}
                        id="group"
                      >
                        <option value="">All</option>
                        <option value="BR">Branded - BR</option>
                        <option value="GR">Generic - GR</option>
                        <option value="AR">Aberrant - AR</option>
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="route_desc">Route Desc</label>
                      <select
                        className="form-control"
                        onChange={this.onChangeSelect}
                        id="route_desc"
                      >
                        <option value="">All</option>
                        <option value="BUCCAL">BUCCAL</option>
                        <option value="DENTAL">DENTAL</option>
                        <option value="EPIDURAL">EPIDURAL</option>
                        <option value="IMPLANTATION">IMPLANTATION</option>
                        <option value="INHALATION">INHALATION</option>
                        <option value="INJECTION">INJECTION</option>
                        <option value="INTRAARTERIAL">INTRAARTERIAL</option>
                        <option value="INTRAARTICULAR">INTRAARTICULAR</option>
                        <option value="INTRACAVERNOSAL">INTRACAVERNOSAL</option>
                        <option value="INTRADERMAL">INTRADERMAL</option>
                        <option value="INTRAMUSCULAR">INTRAMUSCULAR</option>
                        <option value="INTRAOCULAR">INTRAOCULAR</option>
                        <option value="INTRATHECAL">INTRATHECAL</option>
                        <option value="INTRAUTERINE">INTRAUTERINE</option>
                        <option value="INTRAVENOUS">INTRAVENOUS</option>
                        <option value="INTRAVESICAL">INTRAVESICAL</option>
                        <option value="IRRIGATION">IRRIGATION</option>
                        <option value="MISCELLANEOUS">MISCELLANEOUS</option>
                        <option value="MUCOUS MEMBRANE">MUCOUS MEMBRANE</option>
                        <option value="NASAL">NASAL</option>
                        <option value="OPHTHALMIC">OPHTHALMIC</option>
                        <option value="ORAL">ORAL</option>
                        <option value="OTIC(EAR)">OTIC(EAR)</option>
                        <option value="PERFUSION">PERFUSION</option>
                        <option value="RECTAL">RECTAL</option>
                        <option value="SUBCUTANEOUS">SUBCUTANEOUS</option>
                        <option value="SUBLINGUAL">SUBLINGUAL</option>
                        <option value="TOPICAL">TOPICAL</option>
                        <option value="TRANSLINGUAL">TRANSLINGUAL</option>
                        <option value="TRANSDERMAL">TRANSDERMAL</option>
                        <option value="URETHRAL">URETHRAL</option>
                        <option value="VAGINAL">VAGINAL</option>
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="dea">Class</label>
                      <select
                        onChange={this.onChange}
                        value={this.state.dea}
                        id="dea"
                        className="form-control"
                      >
                        <option value="">Please Select</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="2N">2N</option>
                        <option value="3N">3N</option>
                        <option value="NC">NC</option>
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="ahfs_desc">Therapeutic Class</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.ahfs_desc}
                        id="ahfs_desc"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="gcn">GCN</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.gcn}
                        id="gcn"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="gcn_seq">GCN Seq</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.gcn_seq}
                        id="gcn_seq"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="ahfs_desc">Has Coupon</label>
                      <select
                        className="form-control"
                        onChange={this.onChangeSelect}
                        id="website"
                      >
                        <option value="">All</option>
                        <option value="1">Yes</option>
                      </select>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Filter
                  </button>
                  <button
                    type="reset"
                    onClick={this.resetSearch}
                    className="btn btn-secordry"
                  >
                    Reset
                  </button>
                </form>
              </Collapse>
            </div>

            <br></br>
            <ReactDatatable
              config={this.config}
              records={this.state.records}
              columns={this.columns}
              onChange={this.filter}
              dynamic={true}
              total_record={this.state.totalCount}
              loading={true}
              exportToCSV={this.exportToCSV}
              exportToPDF={this.exportToPDF}
              advanceSearch={this.advanceSearch}
            />
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  records: state.records,
  filter: state.filterData,
});

export default connect(mapStateToProps, { logoutUser, setFilter })(Drugs);
