import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import ReactDatatable from "../../utils/ReactDatatable";
import moment from "moment";
import { connect } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";
import GroupFilter from "./Filter/GroupFilter.js";

const status = {
  in_progress: "In Progress",
  successful: "Sent",
  failed: "Failed",
};
const FaxTemplateLog = (props) => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [rx_group_associated, setRxGroupAssociated] = useState("");
  const [filterData, setFilterData] = useState({
    page_number: 1,
    page_size: 20,
    sort_order: { column: "date", order: "desc" },
  });

  const columns = [
    {
      key: "fax",
      text: "Fax Number",
      className: "fax",
      align: "left",
      sortable: false,
      faxLog: true,
    },
    {
      key: "template_name",
      text: "Fax Template",
      className: "template_name",
      align: "left",
      sortable: false,
    },
    {
      key: "status",
      text: "Status",
      className: "status",
      align: "left",
      sortable: false,
    },
    {
      key: "formatted_date",
      text: "Date",
      className: "formatted_date",
      align: "left",
      sortable: false,
    },
  ];

  if (props.auth.user.superadmin) {
    columns.push({
      key: "pharmacy_account",
      text: "Email",
      className: "pharmacy_account",
      align: "left",
      sortable: false,
    });
  }
  const config = {
    page_size: 20,
    length_menu: [20, 50, 100, 500, 1000],
    filename: "Physician List",
    no_data_text: "No record found!",
    button: {
      excel: false,
      print: false,
      csv: false,
    },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: false,
    show_pagination: true,
    show_info: true,
  };
  const onSearch = (e) => {
    e.preventDefault();
    getData();
  };

  console.log(props);
  const getData = () => {
    setLoading(true);
    filterData.search = {
      pharmacy_account:
        rx_group_associated !== undefined && rx_group_associated !== ""
          ? rx_group_associated
          : props.auth.user.final_user,
    };
    axios.post("/faxApi/fax-template-log", filterData).then((res) => {
      var result = [];
      for (let i in res.data.records) {
        let each = res.data.records[i];
        each.formatted_date = moment(each.date).format("LLL");
        each.status = status[each.status];
        result.push(each);
      }
      setTotalCount(res.data.count);
      setLoading(false);
      setRecords(result);
    });
  };
  useEffect(() => {
    getData();
  }, [filterData]);

  const filter = (filterData) => {
    setFilterData({
      ...filterData,
      sort_order: { column: "date", order: "desc" },
    });
  };
  const resetSearch = () => {
    setRxGroupAssociated("");
    setFilterData({
      page_number: 1,
      page_size: 20,
      sort_order: { column: "date", order: "desc" },
    });
  };

  return (
    <div className="d-flex" id="wrapper">
      {loading && (
        <div className="loading-wrapper">
          <PulseLoader color="#007bff" loading={true} size={20} />
        </div>
      )}

      <div id="page-content-wrapper">
        <div className="container-fluid">
          <h1 className="mt-2 text-primary">Fax Logs</h1>
          <br></br>
          <p>
            <h5 style={{ fontWeight: "bold", color: "green" }}>
              Please note: Fax Status can take up to 1 hour to refresh
            </h5>
          </p>
          <button
            className="btn btn-success mt-3 mr-2"
            onClick={() => window.history.go(-1)}
          >
            {"<"} Go to Prescriber List
          </button>
          {props.auth.user.superadmin && (
            <Fragment>
              <GroupFilter
                onChangeSelect={(e) => {
                  setRxGroupAssociated(e.target.value);
                }}
                rx_group_associated={rx_group_associated}
                hide_check={true}
              />
              <button
                onClick={onSearch}
                type="button"
                className="btn btn-primary"
              >
                Search
              </button>
              <button
                type="reset"
                onClick={resetSearch}
                className="btn btn-secordry"
              >
                Reset
              </button>
            </Fragment>
          )}

          <ReactDatatable
            columns={columns}
            config={config}
            records={records}
            total_record={totalCount}
            onChange={filter}
            dynamic={true}
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  multistoreData: state.multistoreData,
});

export default connect(mapStateToProps, null)(FaxTemplateLog);
